import { useEffect } from "react";
import packageJson from "../../package.json"
import { useCookies } from "react-cookie";

/**
 * Keep the app updated with reload page
 */
const Updater = () => {

	const [cookies, setCookies, removeCookies] = useCookies(['focus']);

	// current timestamp in seconds
	let timestamp = parseInt(new Date().getTime()/1000);

	useEffect(() => {
		console.log(packageJson.version)
		window.addEventListener("focus", onFocus);
		// Calls onFocus when the window first loads
		onFocus();
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, []);

	// if window is focused and not updated for 24h hours
	const onFocus = () => {
		// check if cookie focus exists
		if(!cookies.focus) {
			setCookies('focus', timestamp, { path: '/', maxAge:  '86400'})
			window.location.reload()
		}
	};

	return <></>;
};

export default Updater;