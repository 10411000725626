import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';


function GetHome() {

	const [page, setPage] = useState('');
	const [breads, setBreads] = useState([]);
	const [orders, setOrders] = useState([]);
	const [loader, setLoader] = useState(false)
	const [categories, setCategories] = useState([]);
	const [apartment, setApartment] = useState('');

	let arr = [];

	useEffect(() => {
		fetchBread();
		fetchCategories();
		// console.log(loader)
	}, []);

	const fetchBread = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/bread?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				filter: {active:true}
			})
		})
		.then(data => data.json())
		// push to array
		.then(data => {
			for (let i = 0; i < data.entries.length; i++) {
				arr.push(data.entries[i]);
			}
		})
		// sort array by #
		.then(() => {
			arr.sort((a, b) => a.number - b.number);
			setBreads(arr);
			// console.log(arr);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const fetchCategories = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/collection/bread?token=account-9805cfff4b0ec3f5f36f57910991b1')
		.then(collection => collection.json())
		.then(collection => {
			let filter = collection.fields.filter((field) => field.name === 'category');
			setCategories(filter[0].options.options);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	// BACKEND
	const fetchOrders = async () => {
		// Get all orders
		fetch('https://cockpit.dornsberg.net/api/collections/get/brotbestellungen?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(bread => bread.json())
		.then(bread => {
			// console.log(bread);
			setOrders(bread.entries);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	}

	const Orders = () => (
		orders.map((item, i) => {
			let timestamp = new Date(item._created*1000);
			return (
				<div key={i} className="purchase">
					<div className="purchase-date">{timestamp.toLocaleDateString('de', {weekday: 'long'})}, {timestamp.toLocaleDateString('de')} um {timestamp.toLocaleTimeString('de')} Uhr</div>
					<div className="purchase-apt">Apartment: <span className="bold medium">{item.apartment}</span></div>
					<div className="purchases">
						{item.order.map((order, index) => (
							<div key={index} className="order">
								{order.quantity}x {order.name}
							</div>
						))}
					</div>
					<div>Wurde automatisch ins Hotelprogramm übertragen: {item.transmitted ? 'Ja' : 'Nein'}</div>
				</div>
			)
		})
	)

	const Backend = () => {
		if(!orders.length) {
			fetchOrders();
			return <Spinner />;
		}
		else {
			// sort array by timestamp
			orders.sort((a, b) => b._created - a._created)
			return (
				<div className="backend purchases-list">
					<nav className="purchase-filter">
						<button className={apartment === '' ? 'button2 active' : 'button2'} onClick={fetchOrders}>Alle</button>
						<button className={apartment === 'Frieda' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Frieda'); setOrders(orders.filter(item => item.apartment === 'Frieda'))}}>Frieda</button>
						<button className={apartment === 'Paula' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Paula'); setOrders(orders.filter(item => item.apartment === 'Paula'))}}>Paula</button>
						<button className={apartment === 'Martha' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Martha'); setOrders(orders.filter(item => item.apartment === 'Martha'))}}>Martha</button>
						<button className={apartment === 'Franzi' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Franzi'); setOrders(orders.filter(item => item.apartment === 'Franzi'))}}>Franzi</button>
						<button className={apartment === 'Luise' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Luise'); setOrders(orders.filter(item => item.apartment === 'Luise'))}}>Luise</button>
						<button className={apartment === 'Lotte' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Lotte'); setOrders(orders.filter(item => item.apartment === 'Lotte'))}}>Lotte</button>
						<button className={apartment === 'Greta' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Greta'); setOrders(orders.filter(item => item.apartment === 'Greta'))}}>Greta</button>
						<button className={apartment === 'Anni' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Anni'); setOrders(orders.filter(item => item.apartment === 'Anni'))}}>Anni</button>
						<button className={apartment === 'Marie' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Marie'); setOrders(orders.filter(item => item.apartment === 'Marie'))}}>Marie</button>
					</nav>
					<div className='t4 large'>Anzahl Bestellungen: {orders.length}</div>
					<br/>
					<Orders />
				</div>
			)
		}
	}

	const Bestseller = () => {
		if(!orders.length) {
			fetchOrders();
			return <Spinner />;
		}
		else {
			let summary = [];
			for (let i = 0; i < orders.length; i++) {
				orders[i].order.forEach(function(d) {
					if (summary.hasOwnProperty(d.name)) {
						summary[d.name] = summary[d.name] + d.quantity;
					} else {
						summary[d.name] = d.quantity;
					}
				})
			}
			const sortable = Object.fromEntries(
				Object.entries(summary).sort(([,a],[,b]) => b-a)
			);
			let data = '';
			let index = 1;
			for (const [key, value] of Object.entries(sortable)) {
				data += `<div className="stats-item">${index}. ${key}: ${value}</div>`;
				index++;
			}
			return (
				<div className="bestseller">
					<div className="default" dangerouslySetInnerHTML={{__html: data}}></div>
				</div>
			)
		}
	}

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='gourmet-boutique'>
				<div className="product-nav">
					<button className={page === '' ? 'button2 active' : 'button2'} onClick={() => setPage('')}>Alle Bestellungen</button>
					<button className={page === 'bestseller' ? 'button2 active' : 'button2'} onClick={() => setPage('bestseller')}>Bestseller</button>
				</div>
				{loader === true ? <Spinner /> : ''}
				{page === '' ? <Backend /> : ''}
				{page === 'bestseller' ? <Bestseller /> : ''}
		</motion.div>
	)
}

export default GetHome;