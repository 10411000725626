import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';
import * as Constants from '../constants';
import { useCookies } from "react-cookie";
import {FaCheckCircle} from 'react-icons/fa'


function Notification(prop) {

	const [notifications, setNotifications] = useState();
	const [res, setRes] = useState();
	const { register, handleSubmit, formState: { errors } } = useForm({});


	// check for new notifications on page change
	useEffect(() => {
		fetchNotifications();
		// console.log(notifications)
	}, []);

	/**
	 * Get and update notifications from cms
	 */
	const fetchNotifications = async () => {
		fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/notifications',
				content: {
					sort: {_created:-1}
				}
			})
		})
		.then(res => res.json())
		.then(res => setNotifications(res.entries))
		.catch(function() {
			console.log('Fetch error');
		})
	}

	// Send new notification
	const sendMsg = data => {
		fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/save/notifications',
				content: {
					data: data
				}
			})
		})
		.then(res=>res.json())
		.then(res=>setRes(res))
		.catch(function() {
			console.log('Fetch error');
		})
	}

	const Notifier = () => (
		<form className="send-notification" onSubmit={handleSubmit(sendMsg)}>
			<div className="t2 p-b-40">Send notification</div>
			<textarea type="text" cols="40" rows="5" name="text" placeholder="Deutsch" autoComplete="text" {...register("text")} />
			<textarea type="text" cols="40" rows="5" name="text_it" placeholder="Italiano" autoComplete="text" {...register("text_it")} />
			<textarea type="text" cols="40" rows="5" name="text_en" placeholder="English" autoComplete="text" {...register("text_en")} />
			{res ? <FaCheckCircle style={{fontSize: 42}} /> : <input type="submit" value="Send" className="submit button1" />}
		</form>
	)

	const Notifications = () => (
		<div className="notifications p-t-80">
			<div className='t2 p-b-40'>Older notifications</div>
			{notifications.map((note,i) => {
				return <div key={i} className="notification">{new Date(note._created*1000).toLocaleDateString()} {new Date(note._created*1000).toLocaleTimeString()}<br/>{note.text}</div>
			})}
		</div>
	)

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='app-notifications'>
				<Notifier />
				{notifications ? <Notifications /> : <Spinner />}
		</motion.div>
	)
}

export default Notification;