import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form'; // https://react-hook-form.com/
import * as Constants from '../constants';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';
import Datepicker from '../components/Datepicker';
import { addDays } from 'date-fns';
import QRCode from 'react-qr-code'; // https://github.com/rosskhanas/react-qr-code
import Pdf from 'react-to-pdf'; // https://github.com/ivmarcos/react-to-pdf#readme

const ref = React.createRef();

function WelcomeLetter(prop) {

	const [token, setToken] = useState();
	const [guestData, setGuestData] = useState();
	const [guestId, setGuestId] = useState();
	const [letter, setLetter] = useState();
	const { register, handleSubmit, control,  formState: { errors } } = useForm();
	const [arrivalDate, setArrivalDate] = useState(new Date());
	const [departureDate, setDepartureDate] = useState(new Date((new Date()).valueOf() + 1000*3600*24*7));

	// Guest data form
	const LetterSettings = () => (
		<div className="letter-settings centerSmall">
			<div className="t2 p-b-40">Einstellungen für Willkommensbrief</div>
			<form className="settings tal flex-column" onSubmit={handleSubmit(createToken)}>
				<fieldset className="flex-column m-b-15">
					<label htmlFor="lang" className="t5">Sprache</label>
					<select className="button2" {...register("lang", { required: true })}>
						<option value="de">Deutsch</option>
						<option value="it">Italienisch</option>
						<option value="en">Englisch</option>
					</select>
				</fieldset>
				<fieldset className="flex-column m-b-15">
					<label htmlFor="name" className="t5">Nachname des Gastes</label>
					<input type="text" placeholder='Nachname des Gastes' className="button2" {...register("name", {required:true})} autoComplete='Off' />
					{errors.name && <span className='c3'>Bitte ausfüllen</span>}
				</fieldset>
				<fieldset className="flex-column m-b-15">
					<label htmlFor="fidelity" className="t5">Treueprogramm</label>
					<select className="button2" {...register("fidelity")}>
						<option value="guest">Gast (1. Aufenthalt)</option>
						<option value="friend">Stammgast (ab dem 2. Aufenthalt)</option>
						<option value="friend+">Stammgast+ (ab dem 5. Aufenthalt)</option>
					</select>
				</fieldset>
				<fieldset className="flex-column m-b-15">
					<label htmlFor="apartment" className="t5">Apartment</label>
					<select className="button2" {...register("apartment", { required: true })}>
						<option value="">Bitte wählen</option>
						<option value="Frieda">Frieda</option>
						<option value="Paula">Paula</option>
						<option value="Martha">Martha</option>
						<option value="Franzi">Franzi</option>
						<option value="Lotte">Lotte</option>
						<option value="Greta">Greta</option>
						<option value="Anni">Anni</option>
						<option value="Marie">Marie</option>
					</select>
					{errors.apartment && <span className='c3'>Bitte auswählen</span>}
				</fieldset>
				<div className="flex space-between m-b-15">
					<fieldset>
						<label htmlFor="arrival" className="t5">Anreise</label>
						<Controller
							name="arrival"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Datepicker
									inline
									className="button2"
									selected={field.value ? field.value : arrivalDate}
									onChange={(date) => {
										setArrivalDate(date)
										// set depart date + 7 days arrival date
										setDepartureDate(new Date((date).valueOf() + 1000*3600*24*7))
										// set time to 12am and create timestamp in milliseconds
										date = new Date(date.toDateString()).setHours(12)
										// the next line will be submitted
										field.onChange(date)
									}}
								/>
							)}
						/>
						{errors.arrival && <span className='c3'>Bitte bestätigen</span>}
					</fieldset>
					<fieldset>
						<label htmlFor="departure" className="t5">Abreise</label>
						<Controller
							name="departure"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Datepicker
									inline
									minDate={addDays(arrivalDate, 1)}
									className="button2"
									selected={field.value ? field.value : departureDate}
									onChange={(date) => {
										// set time to 12am and create timestamp in milliseconds
										date = new Date(date.toDateString()).setHours(12)
										// the next line will be submitted
										field.onChange(date)
									}}
								/>
							)}
						/>
						{errors.departure && <span className='c3'>Bitte bestätigen</span>}
					</fieldset>
				</div>
				<input type="submit" value="OK" className="submit button2 m-tb-30" />
			</form>
		</div>
	)

	// create qr token with guest data and safe it to db
	const createToken = data => {
		// timestamp to seconds
		data.arrival = data.arrival/1000
		data.departure = data.departure/1000
		setGuestData(data)
		// send n create token
		fetch(Constants.baseUrl+'/php/qr-code.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(res => setToken(res))
		.catch(function() {
			console.log('Fetch error');
		})
	}

	// save token to db
	useEffect(() => {
		if(token) saveToken();
	},[token]);
	const saveToken = () => {
		fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/save/guests',
				content: {
					data: {token}
				}
			})
		})
		.then(res => res.json())
		.then(res => setGuestId(res._id))
		.catch(function() {
			console.log('Fetch error');
		})
	}

	// after qr code get letter text
	useEffect(() => {
		if(guestId) fetchLetter();
	},[guestId]);
	const fetchLetter = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/singletons/get/welcomeLetter?lang='+guestData.lang
			})
		})
		.then(data => data.json())
		.then(data => setLetter(data))
		.catch(function() {
			console.log('Fetch error');
		})
	}

	const PrintLetter = () => {
		if(letter) return (
			<div className="print-letter">
				  <Pdf scale={.95} x={5} targetRef={ref} filename={`${guestData.name}.pdf`}>
					{({ toPdf }) => <button className="button1" onClick={toPdf}>Ausdrucken</button>}
				</Pdf>
				<div ref={ref} className='pdf-wrapper tac p-all-80 p-tb-40'>
					<img className="logo p-b-40" src="./images/logo.svg" />
					<div className="title serif font-large p-b-40">
						{guestData.lang === 'de' ? 'Liebe Familie' : ''}
						{guestData.lang === 'it' ? 'Cara famiglia' : ''}
						{guestData.lang === 'en' ? 'Dear family' : ''}
						&nbsp;<span className="surname">{guestData.name}</span>,
					</div>
					<div className="letter-top sans-serif" dangerouslySetInnerHTML={{ __html: letter.textBefore}}></div>
					<QRCode className="m-t-15 m-b-30" value={`https://app.dornsberg.net/?_id=${guestId}`} size={160} />
					<div className="letter-bottom sans-serif" dangerouslySetInnerHTML={{ __html: letter.textAfter}}></div>
				</div>
			</div>
		)
		else return <Spinner/>
	}

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='welcomeLetter print-content'>
			{!token ? <LetterSettings /> : ''}
			{token ? <PrintLetter /> : ''}
		</motion.div>
	)

}

export default WelcomeLetter;