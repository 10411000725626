import { NavLink } from 'react-router-dom';

const NavMain = (prop)  => {
	return (
		<li className="link flex-center align-center">
			{prop.external
				?
				<a href={prop.pathname} title={prop.title} target="_blank" className="flex-center align-center flex-column">
					<div className="circle flex-center align-center">
						{prop.icon}
					</div>
					<div className="title small">{prop.title}</div>
				</a>
				:
				<NavLink to={{pathname:prop.pathname, title:prop.title}} className="flex-center align-center flex-column">
					<div className="circle flex-center align-center">
						{prop.icon}
					</div>
					<div className="title small">{prop.title}</div>
				</NavLink>
			}
		</li>
	)
}


export default NavMain;