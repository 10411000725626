import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useForm } from 'react-hook-form';
import './css/default.css';
import './css/styleguide.css';
import './css/spacing/space.css';
import './App.css';
import * as Constants from './constants';
import Updater from './components/Updater';
import ErrorPage from './pages/404';
import Spinner from './components/Spinner';
import NavHeader from './components/NavHeader';
import Home from './pages/Home';
import WelcomeLetter from './pages/WelcomeLetter';
import AppNotifier from './pages/AppNotifier';
import GourmetBoutique from './pages/GourmetBoutique';
import BreadService from './pages/BreadService';
import Newsletter from './pages/Newsletter';
import Econcierge from './pages/eConcierge';

function App() {
	const [auth, setAuth] = useState('');
	const { register, handleSubmit, formState: { errors } } = useForm({});

	useEffect(() => {
		fetchToken();
	}, []);

	// Authentication
	// get token
	const fetchToken = () => {
		fetch(Constants.baseUrl+'/php/token.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify('Authorisation request')
		})
		.then(data => data.json())
		.then(data => setAuth(data))
		.catch(function() {
			console.log('Fetch error');
		})
	}

	// Authentication
	const authUser = data => {
		fetch(Constants.baseUrl+'/php/access.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		})
		.then(res=>res.json())
		// .then(res => console.log(res))
		.then(res => setAuth(res));
	}

	const Login = () => (
		<form className="access flex-center align-center" onSubmit={handleSubmit(authUser)}>
			<div className="t4 c4">Password:&nbsp;</div>
			<input type="password" name="password" autoComplete="current-password" {...register("password", { required: true })} />
			<input type="submit" value="OK" className="submit button1 negative" />
		</form>

	)

	if(auth === 'Authentification failed' || auth === 'access denied') return <Login />
	else if(auth === 'access granted') {
		return (
			<Router>
				<div className="App">
					<Updater />
					<main>
						<Route render={({location}) => (
							<AnimatePresence>
								<Switch location={location} key={location.pathname}>
									<Route path ="/" exact component={Home} />
									<Route path ="/welcome-letter" exact component={()=><WelcomeLetter />} />
									<Route path ="/app-notifications" exact component={()=><AppNotifier />} />
									<Route path ="/gourmet-boutique" exact component={()=><GourmetBoutique />} />
									<Route path ="/bread-service" exact component={()=><BreadService />} />
									<Route path ="/e-concierge" exact component={()=><Econcierge />} />
									<Route path ="/newsletter" exact component={()=><Newsletter />} />
									<Route component={ErrorPage} />
								</Switch>
							</AnimatePresence>
						)} />
					</main>
					<header>
						<NavHeader />
					</header>
					<footer>
					</footer>
				</div>
			</Router>
		)
	}
	else return <Spinner />
}

export default App;