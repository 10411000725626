import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';
import * as Constants from '../constants';
import ContentEditable from "react-contenteditable";
// import {FaCheckCircle} from 'react-icons/fa'
// import Tiptap from '../components//Tiptap'

function Newsletter() {

	const [res, setRes] = useState('');
	const { register, handleSubmit, control, watch, formState: { errors } } = useForm();
	const type = watch('type')
	const lang = watch('lang')

	function transformation(data) {
		if(!data) return !lang
			? Mailformat
			: lang === 'de'
				? Mailformat
					: lang === 'it'
						? Mailformat_it
						: lang === 'en'
							? Mailformat_en
							: ''
		else return data
	}

	// Send new notification
	const sendMsg = async data => {
		// setRes('sending');
		const receiversArr = data.receivers.split('\n');
		for (let index = 0; index < receiversArr.length; index++) {
			const receiver = receiversArr[index];
			let salutation, gender, name, mail, el;
			let perMsg = data.msg;
			el = receiver.split('\t');
			gender = el[0];
			name = el[1];
			mail = el[2];
			if(!mail) mail = receiver;
			if(data.test) mail = 'friedrich.illmer@gmail.com';
			if(gender === 'Herr') salutation = 'Sehr geehrter Herr '+name;
			else if(gender === 'Frau') salutation = 'Sehr geehrte Frau '+name;
			else salutation = 'Liebe Familie '+name;
			perMsg = JSON.stringify(perMsg).replace('{salutation with surname}', salutation);
			perMsg = JSON.parse(perMsg);
			console.log({
				type: data.type,
				subject: data.subject,
				msg: perMsg,
				receiver: mail,
				test: data.test,
				name: name
			})
			await fetch('https://manager.dornsberg.net/php/newsletter.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					type: data.type,
					subject: data.subject,
					msg: perMsg,
					receiver: mail,
					test: data.test,
					name: name
				})
			})
			.then(res=>res.json())
			.then(res => {
				return new Promise((resolve, reject) => {
					console.log(res)
					setTimeout(() => resolve(), 1500);
				});
			})
			.catch(function() {
				console.log('Fetch error');
			})
		}
	}

	const NewsletterForm = () => (
		<form className="send-notification" onSubmit={handleSubmit(sendMsg)}>
			<div className="flex">
				<fieldset className="flex-column m-b-30">
					<select {...register("type", { required: true })}>
						<option value="mail">Single mail</option>
						<option value="newsletter">Newsletter</option>
					</select>
				</fieldset>
				<fieldset className="flex-column m-b-15">
					<select {...register("lang", { required: true })}>
						<option value="de">Deutsch</option>
						<option value="it">Italienisch</option>
						<option value="en">Englisch</option>
					</select>
				</fieldset>
			</div>
			<fieldset className="flex-column">
				<label htmlFor="receivers" className="t5 tal">Empfänger</label>
				<textarea style={{border: '1px solid #ccc'}} type="text" cols="40" rows={type === 'newsletter' ? 5 : 2} name="receivers" placeholder={type === 'newsletter' ? 'Salutation /tab/ surname /tab/ email (1 per line)' : 'single email address'} autoComplete="Off" {...register("receivers")} />
				{errors.receivers && <span className='c3'>Bitte ausfüllen</span>}
			</fieldset>
			<fieldset className="flex-column">
				<label htmlFor="subject" className="t5 tal">Betreff</label>
				<textarea style={{border: '1px solid #ccc'}} type="text" cols="40" rows="2" name="subject" placeholder="Subject" autoComplete="Off" {...register("subject", {required:true})} />
				{errors.subject && <span className='c3'>Bitte ausfüllen</span>}
			</fieldset>
			<fieldset className="flex-column">
				<label htmlFor="msg" className="t5 tal">Nachricht</label>
				<Controller
					name="msg"
					control={control}
					render={({field}) => (
						<div className='editor' style={{border: '1px solid #ccc'}}>
							{/* <div className="toolbar">
								<button className='button2' onClick={(e) => {
									e.preventDefault()
									document.execCommand('bold', false, null)
								}}>fett</button>
								<button className='button2' onClick={(e) => {
									e.preventDefault()
									document.execCommand('italic', false, null)
								}}>kursiv</button>
							</div> */}
							<ContentEditable
								id='msg'
								onChange={field.onChange}
								html={transformation(field.value)}
							/>
						</div>
					)}
				/>
				{errors.receivers && <span className='c3'>Bitte ausfüllen</span>}
			</fieldset>
			<fieldset className="m-tb-30">
				<input type="checkbox" {...register("test")} />
				<label htmlFor="test" className="t5">Testmail senden</label>
			</fieldset>
			{res === 'sending'
				? <Spinner />
				: <input type="submit" value="Send" className="submit button1" />
					// ? <FaCheckCircle style={{fontSize: 42}} />
					// : <input type="submit" value="Send" className="submit button1" />
			}
		</form>
	)

	const Mailformat = `
		<table style="width:600px; padding: 0px; margin: 0px auto; background: #ffffff; text-align: center; border-spacing: 0; table-layout:fixed;" align="center" border="0" cellpadding="0" cellspacing="0">
			<tbody>
				<tr style="padding: 0px; margin: 0px; border-spacing: 0;">
					<td width="600" bgcolor="#ffffff" style="background-color: #ffffff;">
						<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="margin: 0; padding: 0; padding-top: 40px; padding-bottom: 40px; text-align:center; background-color: #ffffff;">
									<img src="https://files.dornsberg.net/mailformat/header.jpg" alt="Dornsberg Panoramic Apartments" width="600" height="285" border="0" style="width: 600px; height: 285px; margin: 0 auto; max-width:600px; padding: 0; display:block;" />
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:22px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-bottom: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:22px; mso-line-height-rule:exactly; line-height:28px; color:#000; font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										${type !== 'newsletter' ? 'Sehr geehrter Herr, Sehr geehrte Frau,' : '{salutation with surname},'}
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; padding-bottom: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; color:#333; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										text
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; color:#333; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										Herzliche Grüße aus Schenna
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; text-align:center; background-color: #ffffff;">
									<img src="https://files.dornsberg.net/mailformat/signature-anim.gif" alt="Fam. Illmer" width="185" height="60" border="0" style="width: 185px; height: 60px; margin: 0 auto; max-width:185px; padding: 0; display:block;" />
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0;">
								<td width="600" bgcolor="#ffffff" style="background-color: #ffffff; padding-top: 30px;">
									<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
										<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
											<td align="center" style="font-size:20px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-bottom: 10px; text-align:center; background-color: #ffffff;">
												<p style="font-size:20px; mso-line-height-rule:exactly; line-height:28px; color:#000; font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
													Dornsberg Panoramic Apartments****
												</p>
											</td>
										</tr>
										<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
											<td width="600" bgcolor="#ffffff" style="background-color: #ffffff; border-top: 1px solid #ccc;">
												<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
													<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
														<td style="padding: 0; padding-top: 10px; margin: 0; text-align: center; background-color: #ffffff;">
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																<span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">St. Georgener Str. 32b, 39017 Schenna, Südtirol - Italien</span>
															</p>
														</td>
													</tr>
													<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
														<td style="padding: 0; padding-bottom: 31px; margin: 0; text-align: center; background-color: #ffffff;">
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																<a href="tel:00390473943028" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">+39&nbsp;0473&nbsp;94&nbsp;30&nbsp;28</span></a>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="mailto:info@dornsberg.net" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">info@dornsberg.net</span></a>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="https://www.dornsberg.net" target="_blank" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">www.dornsberg.net</span></a>
															</p>
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																CIN IT021087B4KKQSAYSI
															</p>
														</td>
													</tr>
												</table>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</tbody>
		</table>`
	;
	const Mailformat_it = `
		<table style="width:600px; padding: 0px; margin: 0px auto; background: #ffffff; text-align: center; border-spacing: 0; table-layout:fixed;" align="center" border="0" cellpadding="0" cellspacing="0">
			<tbody>
				<tr style="padding: 0px; margin: 0px; border-spacing: 0;">
					<td width="600" bgcolor="#ffffff" style="background-color: #ffffff;">
						<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="margin: 0; padding: 0; padding-top: 40px; padding-bottom: 40px; text-align:center; background-color: #ffffff;">
									<img src="https://files.dornsberg.net/mailformat/header.jpg" alt="Dornsberg Panoramic Apartments" width="600" height="285" border="0" style="width: 600px; height: 285px; margin: 0 auto; max-width:600px; padding: 0; display:block;" />
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:22px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-bottom: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:22px; mso-line-height-rule:exactly; line-height:28px; color:#000; font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										${type !== 'newsletter' ? 'Gentile Signore, Gentile Signora,' : '{salutation with surname},'}
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; padding-bottom: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; color:#333; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										text
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; color:#333; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										Cordiali saluti dall'Alto Adige
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; text-align:center; background-color: #ffffff;">
									<img src="https://files.dornsberg.net/mailformat/signature-anim.gif" alt="Fam. Illmer" width="185" height="60" border="0" style="width: 185px; height: 60px; margin: 0 auto; max-width:185px; padding: 0; display:block;" />
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0;">
								<td width="600" bgcolor="#ffffff" style="background-color: #ffffff; padding-top: 30px;">
									<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
										<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
											<td align="center" style="font-size:20px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-bottom: 10px; text-align:center; background-color: #ffffff;">
												<p style="font-size:20px; mso-line-height-rule:exactly; line-height:28px; color:#000; font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
													Dornsberg Panoramic Apartments****
												</p>
											</td>
										</tr>
										<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
											<td width="600" bgcolor="#ffffff" style="background-color: #ffffff; border-top: 1px solid #ccc;">
												<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
													<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
														<td style="padding: 0; padding-top: 10px; margin: 0; text-align: center; background-color: #ffffff;">
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																<span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">Via San Giorgio 32b, 39017 Scena, Alto Adige (BZ)</span>
															</p>
														</td>
													</tr>
													<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
														<td style="padding: 0; padding-bottom: 31px; margin: 0; text-align: center; background-color: #ffffff;">
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																<a href="tel:00390473943028" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">+39&nbsp;0473&nbsp;94&nbsp;30&nbsp;28</span></a>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="mailto:info@dornsberg.net" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">info@dornsberg.net</span></a>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="https://www.dornsberg.net" target="_blank" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">www.dornsberg.net</span></a>
															</p>
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																CIN IT021087B4KKQSAYSI
															</p>
														</td>
													</tr>
												</table>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</tbody>
		</table>`
	;
	const Mailformat_en = `
		<table style="width:600px; padding: 0px; margin: 0px auto; background: #ffffff; text-align: center; border-spacing: 0; table-layout:fixed;" align="center" border="0" cellpadding="0" cellspacing="0">
			<tbody>
				<tr style="padding: 0px; margin: 0px; border-spacing: 0;">
					<td width="600" bgcolor="#ffffff" style="background-color: #ffffff;">
						<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="margin: 0; padding: 0; padding-top: 40px; padding-bottom: 40px; text-align:center; background-color: #ffffff;">
									<img src="https://files.dornsberg.net/mailformat/header.jpg" alt="Dornsberg Panoramic Apartments" width="600" height="285" border="0" style="width: 600px; height: 285px; margin: 0 auto; max-width:600px; padding: 0; display:block;" />
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:22px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-bottom: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:22px; mso-line-height-rule:exactly; line-height:28px; color:#000; font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										${type !== 'newsletter' ? 'Dear Mr., Dear Ms.,' : '{salutation with surname},'}
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; padding-bottom: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; color:#333; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										text
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; text-align:center; background-color: #ffffff;">
									<p style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; color:#333; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
										Kind regards from South Tyrol
									</p>
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
								<td align="center" style="font-size:16px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-top: 20px; text-align:center; background-color: #ffffff;">
									<img src="https://files.dornsberg.net/mailformat/signature-anim.gif" alt="Fam. Illmer" width="185" height="60" border="0" style="width: 185px; height: 60px; margin: 0 auto; max-width:185px; padding: 0; display:block;" />
								</td>
							</tr>
							<tr style="padding: 0px; margin: 0px; border-spacing: 0;">
								<td width="600" bgcolor="#ffffff" style="background-color: #ffffff; padding-top: 30px;">
									<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
										<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
											<td align="center" style="font-size:20px; mso-line-height-rule:exactly; line-height:28px; margin: 0; padding: 0; padding-bottom: 10px; text-align:center; background-color: #ffffff;">
												<p style="font-size:20px; mso-line-height-rule:exactly; line-height:28px; color:#000; font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
													Dornsberg Panoramic Apartments****
												</p>
											</td>
										</tr>
										<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
											<td width="600" bgcolor="#ffffff" style="background-color: #ffffff; border-top: 1px solid #ccc;">
												<table align="center" bgcolor="#ffffff" width="600" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: collapse;">
													<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
														<td style="padding: 0; padding-top: 10px; margin: 0; text-align: center; background-color: #ffffff;">
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																<span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">Via San Giorgio 32b, 39017 Scena, South Tyrol - Italy</span>
															</p>
														</td>
													</tr>
													<tr style="padding: 0px; margin: 0px; border-spacing: 0; font-size:0; mso-line-height-alt:0; mso-margin-top-alt:1px;">
														<td style="padding: 0; padding-bottom: 31px; margin: 0; text-align: center; background-color: #ffffff;">
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																<a href="tel:00390473943028" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">+39&nbsp;0473&nbsp;94&nbsp;30&nbsp;28</span></a>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="mailto:info@dornsberg.net" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">info@dornsberg.net</span></a>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="https://www.dornsberg.net" target="_blank" style="font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;"><span style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; color:#333; text-decoration: none;">www.dornsberg.net</span></a>
															</p>
															<p style="font-size:13px; mso-line-height-rule:exactly; line-height:30px; color:#333; text-decoration: none; font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; margin: 0; padding: 0; text-align: center; font-weight: normal;">
																CIN IT021087B4KKQSAYSI
															</p>
														</td>
													</tr>
												</table>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</tbody>
		</table>`
	;

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='newsletter'>
				<NewsletterForm />
				{/* <Tiptap setMsg={setMsg} /> */}
				<br/>
				{res ? <div dangerouslySetInnerHTML={{ __html: res}}></div> : ''}
		</motion.div>
	)
}

export default Newsletter;