// https://reactdatepicker.com/
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (prop) => {
	const month = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
	const weekday = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];

	registerLocale('de', {
		localize: {
			month: n => month[n],
			day: n => weekday[n]
		},
		formatLong: {
			date: () => 'dd.MM.yyyy'
		}
	});

	return (
		<DatePicker
			locale="de"
			dateFormat="dd.MM.yyyy"
			calendarStartDay={1}
			minDate={prop.minDate ? prop.minDate : new Date()}
			inline={prop.inline}
			className={prop.className}
			selected={prop.selected}
			onChange={prop.onChange}
		/>
	);

}

export default Datepicker;