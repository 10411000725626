import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';


function Econcierge() {

	const [orders, setOrders] = useState([]);
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		fetchConcierge();
	}, []);

	const fetchConcierge = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/econcierge?token=account-9805cfff4b0ec3f5f36f57910991b1')
		.then(data => data.json())
		.then(data => {
			setOrders(data.entries)
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const Orders = () => (
		orders.map((item, i) => {
			let timestamp = new Date(item._created*1000);
			return (
				<div key={i} className="purchase">
					<div className="purchase-date">{timestamp.toLocaleDateString('de', {weekday: 'long'})}, {timestamp.toLocaleDateString('de')} um {timestamp.toLocaleTimeString('de')} Uhr</div>
					<div dangerouslySetInnerHTML={{__html: item.econcierge}} />
					<div>Wurde automatisch ins Hotelprogramm übertragen: {item.transmitted ? 'Ja' : 'Nein'}</div>
				</div>
			)
		})
	)

	const OrderList = () => {
		if(!orders.length) {
			fetchConcierge();
			return <Spinner />;
		}
		else {
			// sort array by timestamp
			orders.sort((a, b) => b._created - a._created)
			return (
				<div className="backend purchases-list">
					<div className='t4 large'>Anzahl Bestellungen: {orders.length}</div>
					<br/>
					<Orders />
				</div>
			)
		}
	}

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='e-concierge'>
				{loader === true ? <Spinner /> : ''}
				<OrderList />
		</motion.div>
	)
}

export default Econcierge;