export let baseUrl = window.location.origin
if(window.location.hostname === 'localhost') baseUrl = 'http://localhost:9000';
export const cockpit = 'https://cockpit.dornsberg.net'

export const date = new Date();
export let day = date.getDate();
if(day < 10) day = '0'+day;
export let tomorrow = date.getDate()+1;
if(tomorrow < 10) tomorrow = '0'+tomorrow;
export let month = date.getMonth()+1;
if(month < 10) month = '0'+month;
export const year = date.getFullYear();

// PAGE TRANSITION //
export const pageTransition = {
	initial: {
		x: '100vw',
		opacity: 0
	},
	in: {
		x: 0,
		opacity: 1
	},
	out: {
		x: '100vw',
		opacity: 0
	},
	transition: {
		duration: .25,
		ease: 'linear'
	},
	style: {
		position: "absolute",
		width: '100%'
	}
};

// TRANSLATIONS //
export let lang = navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2);
// default lang is en
if (lang !== 'de' && lang !== 'it' && lang !== 'en') lang = 'en';
// change html lang tag
document.documentElement.lang = lang;